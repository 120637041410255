import ScrollDirection from '@anakinyuen/scroll-direction';
import { type } from 'os';

type posInfo = {
    dom: HTMLElement,
    pos:number
}
export class Header{
    private scrollDirection ;
    private header = document.querySelector('.lHeader__holder');
    private headerBusinessSp: posInfo = {
        dom: document.querySelector('.mHeader__business--wrapperSP'),
        pos: document.querySelector('.mHeader__business--wrapperSP').getBoundingClientRect().top + window.pageYOffset
    };
    private headerBannerSp: posInfo = {
        dom:  document.querySelector('.lHeader__banners--holderSP'),
        pos: document.querySelector('.lHeader__banners--holderSP').getBoundingClientRect().top + window.pageYOffset
    }
    private siteNavi: posInfo = {
        dom: document.querySelector('.mSiteLink__holder'),
        pos: document.querySelector('.mSiteLink__holder').getBoundingClientRect().top + window.pageYOffset
    }
    private pageMain: HTMLElement = document.querySelector('.lPage__main--holder');
    private triggerPos = 0;
    constructor(){
        this.scrollDirection = new ScrollDirection({
          eventTarget:document
        });
        this.scrollDirection.start();
        // this.calcTriggerPos();
        this.addEvent();
    }
    calcTriggerPos(){
        this.triggerPos = this.pageMain.getBoundingClientRect().top + window.pageYOffset;
        const triggerObj = document.querySelector('.lHeader__trigger');
        if(null != triggerObj){
            this.triggerPos = triggerObj.getBoundingClientRect().top + window.pageYOffset;
        }
    }
    addEvent(){
        window.addEventListener('scroll',()=>{
            // this.calcTriggerPos();
            if (this.checkReset(this.headerBusinessSp) || this.checkReset(this.headerBannerSp) || this.checkReset(this.siteNavi)) {
                this.headerBusinessSp.dom.style.transition = 'none';
                this.headerBannerSp.dom.style.transition = 'none';
                this.siteNavi.dom.style.transition = 'none';

                this.headerBusinessSp.dom.style.transform = ''
                this.headerBannerSp.dom.style.transform = ''
                this.siteNavi.dom.style.transform = '';

                this.headerBusinessSp.dom.style.transition = '';
                this.headerBannerSp.dom.style.transition = '';
                this.siteNavi.dom.style.transition = '';

            }
        })
        window.addEventListener('resize', () => {
            this.calcTriggerPos();
        })
        const UpEvent = new Event('on_scroll_up'),
            DownEvent = new Event('on_scroll_down');
        document.addEventListener('up', event => {
            this.onUp();
        });
        document.addEventListener('down', event => {
            this.onDown();
        });
    }



    onUp(){
        const me = this,
            mqPC = window.matchMedia('(min-width: 1021px)'),
            mqNarrowPC = window.matchMedia('(min-width: 768px) and (max-width: 1020px)'),
            mqSPPortrait = window.matchMedia('(max-width: 767px) and (orientation: portrait)'),
            mqSPLandscape = window.matchMedia('(max-width: 767px) and (orientation: landscape)'),
            calcVw = function(px,base){
                return px / base * 100 + 'vw';
            }
        if (!this.checkReset(this.headerBusinessSp) && !this.checkReset(this.headerBannerSp) && !this.checkReset(this.siteNavi)) {
            if(mqSPLandscape.matches){
                this.headerBannerSp.dom.style.transform = 'translate3d(0,'+calcVw(60,667)+',0)';
                this.siteNavi.dom.style.transform = 'translate3d(0,'+calcVw(118,667)+',0)';

            }else if(mqSPPortrait.matches){
                this.headerBannerSp.dom.style.transform = 'translate3d(0,'+calcVw(96,375)+',0)';
                this.headerBusinessSp.dom.style.transform = 'translate3d(0,'+calcVw(120,375)+',0)';
            }else if(mqNarrowPC.matches){
                this.siteNavi.dom.style.transform = 'translate3d(0,140px,0)';
                this.headerBannerSp.dom.style.transform = 'translate3d(0,140px,0)';
            }else{
                this.siteNavi.dom.style.transform = 'translate3d(0,100%,0)';
                this.headerBannerSp.dom.style.transform = 'translate3d(0,100%,0)';
                this.headerBusinessSp.dom.style.transform = 'translate3d(0,100%,0)';
            }
        }
    }
    onDown(){
        this.resetHeaderStyle();
    }
    resetHeaderStyle(){
        let me = this;
        this.headerBusinessSp!.dom.style.transform = ''
        this.headerBannerSp!.dom.style.transform = '';
        this.siteNavi!.dom.style.transform = '';
    }
    checkReset(obj){
        //|| window.pageYOffset >= this.triggerPos
        return (window.pageYOffset <= obj.pos ) ? true : false;
    }

}