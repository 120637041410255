import MatchHeight from '../../node_modules/matchheight/dist/MatchHeight';
import { Header } from "./Modules/common/header";
import { Drawer } from "./Modules/common/drawer";
import {Device} from "./Modules/common/Device";
import {Calendar} from "./Modules/common/calendar";
// let Calendar = require("./Modules/common/calendar");

(function () {
    let header = new Header();
    let drawer = new Drawer();
    let calendar = new Calendar();
    MatchHeight.update();
    new Device();
})();
