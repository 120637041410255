export class Calendar{
// function Calendar(){
    constructor(){
        this.dom = {
            tablesOuter: document.querySelector('.business-calendar-box'),
            tablesWrappers: document.querySelectorAll('.business-calendar-box > div'),
            tables: document.querySelectorAll('.business-calendar'),
            days: document.querySelectorAll('.business-calendar td'),
            tagStart: '<span class="mCalendar__num--wrapper">',
            tagMark: '<span class="mCalendar__mark--wrapper">'
        };
        this.init();    
    }
    monthEn = function(m){
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return months[parseInt(m.replace('0','')) - 1];
    }
    init = function(){
        let me = this;
        if(null === this.dom.tablesOuter || 'undefined' === typeof this.dom.tablesOuter){return}
        this.dom.tagMark += '<i class="mCalendar__mark mCalendar__mark--attr0"></i>';
        this.dom.tagMark += '<i class="mCalendar__mark mCalendar__mark--attr1"></i>';
        this.dom.tagMark += '<i class="mCalendar__mark mCalendar__mark--attr2"></i>';
        this.dom.tagMark += '<i class="mCalendar__mark mCalendar__mark--attr3"></i></span>';
        this.dom.tablesOuter.classList.add('mCalendar__table--outer');
        Array.prototype.forEach.call(me.dom.tables,function(table){
            table.classList.add('mCalendar__table')
        });
        Array.prototype.forEach.call(me.dom.days,function(day){
            var d = '<span class="mCalendar__num">' + day.textContent+'</span>';
            day.innerHTML = me.dom.tagStart + d + me.dom.tagMark + '</span>'
        });
        this.setAttr();
        this.setMonth();
        this.button();
    
    }
    setAttr = function(){
        var _d = '',me = this;
        attrDatas.forEach(function(d,i){
            d['setData'] = new Set(d.datas) 
        })
        Array.prototype.forEach.call(this.dom.days,function(day){
            if(!day.getAttribute('id')){return};
            _d = day.getAttribute('id').replace('day','');
            attrDatas.forEach(function(d,i){
                if(d.setData.has(_d)){
                    day.classList.add('attr'+i)
                }
    
            })
        })
    }
    setMonth = function(){
        var me = this;
        Array.prototype.forEach.call(me.dom.tablesWrappers,function(tablesWrapper ,i){
            tablesWrapper.classList.add('mCalendar__table--wrapper');
            if(0 === i){tablesWrapper.classList.add('-current');}
            var caption = tablesWrapper.querySelector('caption'),
                y = caption.textContent.split(',')[0],
                m = caption.textContent.split(',')[1];
                caption.classList.add('mCalendar__date--wrapper');
                caption.innerHTML = '<span class="mCalendar__date">' + y + '<span class="mCalendar__date--unit">年</span>'+ m + '<span class="mCalendar__date--unit">月</span></span>';
        });
    }
    button = function(){
        let me = this,
        createButton = function(i,wrapper){
            let dataWrapper = wrapper.querySelector('.mCalendar__date--wrapper'),
            prev = document.createElement('a'),
            next = document.createElement('a');
            prev.classList.add('mCalendar__button');
            next.classList.add('mCalendar__button');
            prev.classList.add('mCalendar__button--prev');
            next.classList.add('mCalendar__button--next');
            next.addEventListener('click',function(e){
                e.preventDefault();
                resetCurrent();
                setTimeout(function(){
                    setCurrent(i + 1);
                },100)
            });
            prev.addEventListener('click',function(e){
                e.preventDefault();
                resetCurrent();
                setTimeout(function(){
                    setCurrent(i - 1);
                },100)
            });

            prev.dataset.target = i - 1;
            dataWrapper.prepend(prev);
            next.dataset.target = i + 1;
            dataWrapper.append(next);

            if(0 === i){
                prev.setAttribute('disabled',true);
            }
            if(2 === i){
                next.setAttribute('disabled',true);
            }

        },
        resetCurrent = function(){
            Array.prototype.forEach.call(me.dom.tablesWrappers,function(tableWrapper,i){
                tableWrapper.classList.remove('-current')
            })    
        },
        setCurrent = function(goto){
            me.dom.tablesWrappers[goto].classList.add('-current')
        }
        Array.prototype.forEach.call(this.dom.tablesWrappers,function(tableWrapper,i){
            createButton(i,tableWrapper)
            // if(0 === i){return}
        })
    }
}

