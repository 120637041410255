  window.Device = function Device() {
    this.document = window.document.documentElement
    this.ua = window.navigator.userAgent.toLowerCase();
    // console.log("Device's constructor", this);
    this._manageClass();
    return this;
  };

  var p = window.Device.prototype;

  p.isMac = function(){
    return this._check('mac');
  }
  p.isWindows = function () {
    return this._check('windows');
  }

  p.isIphone = function(){
    return !this.isWindows() && this._check('iphone');
  }

  p.isIpad = function(){
    return this._check('ipad') || (this.isMac() && 'ontouchend' in document);
  }

  p.isIpod = function(){
    return this._check('ipod');
  }

  p.isIOs = function(){
    return this.isIphone() || this.isIpod() || this.isIpad();
  }

  p.isAndroid = function(){
    return this._check('android');
  }

  p.isIE = function(){
    return this._check('msie') || this._check('trident');
  }

  p.isNewEdge = function () {
    return this._check('edg');
  }

  p.isOldEdge = function(){
    return this._check('edge');
  }

  p.isChrome = function(){
    return this._check('chrome');
  }

  p.isSafari = function () {
    return this._check('safari');
  }

  p.isFirefox = function(){
    return this._check('firefox');
  }

  /**
   * @param　_type string
   * @return　boolean 
   */
  p._check = function(_type){
    return this.ua.indexOf(_type) !== -1;
  }

  p._hasClass = function(className) {
    return this.document.className.match(new RegExp(className, 'i'));
  }
  p._addClass = function(className) {
    var currentClassName = null
    if (!this._hasClass(currentClassName)) {
      currentClassName = this.document.className.replace(/^\s+|\s+$/g, '');
      this.document.className = currentClassName + ' ' + className;
    }
  }
  p._removeClass = function(className) {
    if (this._hasClass(className)) {
      this.document.className = this.document.className.replace(' '+className,'')
    }
  }

  p._manageClass = function(){
    if (this.isIOs()) {
      if (this.isIpad()) {
        this._addClass('ios ipad');
      } else if (this.isIphone()) {
        this._addClass('ios iphone');
      } else if (this.isIpod()) {
        this._addClass('ios ipod');
      }
    } else if (this.isMac()) {
      if (this.isNewEdge()) {
        this._addClass('macos edge');
      } else if (this.isOldEdge()) {
        this._addClass('macos oldEdge');
      } else if (this.isChrome()) {
        this._addClass('macos chrome');
      } else if (this.isSafari()) {
        this._addClass('macos safari');
      } else if (this.isFirefox()) {
        this._addClass('macos firefox');
      }
    } else if (this.isAndroid()) {
      this._addClass('android');
    } else if (this.isWindows()) {
      if (this.isIE()) {
        this._addClass('windows ie');
      } else if (this.isNewEdge() && !this.isOldEdge()) {
        this._addClass('windows edge');
      } else if (this.isOldEdge()) {
        this._addClass('windows oldEdge');
      } else if (this.isChrome()) {
        this._addClass('windows chrome');
      } else if (this.isSafari()) {
        this._addClass('windows safari');
      } else if (this.isFirefox()) {
        this._addClass('windows firefox');
      }
    }    
  }

  export const Device = window.Device;
