export class UserHelpers {
  // static extend(out) {
  //   out = out || {};

  //   for (var i = 1; i < arguments.length; i++) {
  //     if (!arguments[i]) continue;

  //     for (var key in arguments[i]) {
  //       if (arguments[i].hasOwnProperty(key)) out[key] = arguments[i][key];
  //     }
  //   }

  //   return out;
  // };
  // static isNodeList(nodes) {
  //   var stringRepr = Object.prototype.toString.call(nodes);

  //   return (
  //     typeof nodes === 'object' &&
  //     /^\[object (HTMLCollection|NodeList|Object)\]$/.test(stringRepr) &&
  //     typeof nodes.length === 'number' &&
  //     (nodes.length === 0 ||
  //       (typeof nodes[0] === 'object' && nodes[0].nodeType > 0))
  //   );
  // };
  // static isiOS(){
  //   return window.navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ||
  //     window.navigator.userAgent.toLowerCase().indexOf('ipad') > -1 ||
  //     (window.navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && 'ontouchend' in document);
  // };
  static width(el){
    return parseFloat(getComputedStyle(el, null).width.replace('px', ''));
  };
  static height (el) {
    return parseFloat(getComputedStyle(el, null).height.replace('px', ''));
  };
  // static bodyFixedOn() {
  //   var _scrollTop =
  //     document.documentElement.scrollTop || document.body.scrollTop;
  //   if (UserHelpers.isiOS()) {
  //     document.body.style.position = 'fixed';
  //     document.body.style.top = 0 - _scrollTop + 'px';
  //     document.body.dataset.sctop = _scrollTop + '';
  //   } else {
  //     document.body.style.overflow = 'hidden';
  //   }
  // };
  // static bodyFixedOff(){
  //   document.body.removeAttribute('style');
  //   if (UserHelpers.isiOS()) {
  //     window.scrollTo(0, parseInt(document.body.getAttribute('data-sctop'), 10));
  //     document.body.removeAttribute('data-sctop')
  //   }
  // };
  // static hasScrollBarY(holder) {
  //   return (null != holder) ? holder.scrollHeight > holder.clientHeight : false;
  // };
  static findProperty(elm){
    let el = document.createElement('div');
    for (let key in elm) {
      if (elm.hasOwnProperty(key) && el.style[key] !== undefined) {
        return elm[key];
      }
    }
  };
  static transitionEnd(){
    return UserHelpers.findProperty({
      transition: 'transitionend',
      MozTransition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd'
    })
  };
  static animationEnd(){
    return UserHelpers.findProperty({
      animation: 'animationend',
      MozAnimation: 'mozAnimationEnd',
      WebkitAnimation: 'webkitAnimationEnd'
    })    
  }
};

