import {UserHelpers} from '../../helper.main';

export class Drawer{
    private opener: HTMLElement = document.querySelector('.mDrawer__menu--wrapper');
    private holder: HTMLElement = document.querySelector('.mDrawer__outer');
    constructor(){
        this.addEvent();
        this.closePageLink();
        // console.log('Drawer',this)
    }
    addEvent(){
        const html = document.documentElement;
        this.opener.addEventListener('click',e =>{
            e.preventDefault();
            if(html.classList.contains('-menuMoving')){return};
            html.classList.add('-menuMoving');
            if (html.classList.contains('-menuOpened')){
                html.classList.remove('-menuOpened');
            }else{
                html.classList.add('-menuOpened');
            }
        });
        this.holder.addEventListener(UserHelpers.transitionEnd(),e=>{
            if ('transform' !== e.propertyName) { return }
            html.classList.remove('-menuMoving');
        });
    }
    closePageLink(){
        if('index' !== document.body.getAttribute('data-page')){return};
        let me = this,
            click = function(e){
                me.opener.click();
        }
        Array.prototype.forEach.call(this.holder.querySelectorAll('[href*="/#"]'),function(link){
            link.addEventListener('click',function(e){
                click(e)
            })
        })
        
    }
}